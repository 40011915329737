import React from 'react';
import { connect } from 'react-redux';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { PlotNavButton } from 'components/plot-nav-button';
import { Specifications } from 'components/specifications';
import Paper from '@material-ui/core/Paper';
import { getSegmentsSpecs, getGapData, getMisalignmentData } from 'redux/selectors';

import Plot from 'react-plotly.js';

const GAP = 'Gap';
const MISALIGNMENT = 'Misalignment';
const ROLLER_ROTATION = 'Roller-Rotatio';

class _RcTool extends React.PureComponent {

  state = {
    selectedPlot: GAP,
  }

  onClickGap = () => {
    if (this.state.selectedPlot !== GAP) {
      this.setState({selectedPlot: GAP});
    }
  }

  onClickMisalignment = () => {
    if (this.state.selectedPlot !== MISALIGNMENT) {
      this.setState({selectedPlot: MISALIGNMENT});
    }
  }

  onClickTest = () => {
    if (this.state.selectedPlot !== ROLLER_ROTATION) {
      this.setState({selectedPlot: ROLLER_ROTATION});
    }
  }

  render () {

    const { specs, gapData, misalignmentData } = this.props;
    const { selectedPlot } = this.state;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>
            <div className="grid-default">
              <div className="grid-default__item x12">
                <div className="graphics">
                  <div className="graphics__panel">
                    <Paper className="paper">
                      <Plot
                        useResizeHandler={true}
                        data={selectedPlot === GAP ? gapData : misalignmentData}
                        style={{width: '100%', height: '100%'}}
                        layout={{autosize: true, title: selectedPlot}}
                      />
                    </Paper>
                  </div>
                  <div className="graphics__nav">
                    <PlotNavButton label={'Gap'} imageSrc={'/assets/images/content/example-185x60-1.jpg'} onClick={this.onClickGap}/>
                    <PlotNavButton label={'Misalignment'} imageSrc={'/assets/images/content/example-185x60-2.jpg'} onClick={this.onClickMisalignment}/>
                    <PlotNavButton label={'Roller-Rotatio'} imageSrc={'/assets/images/content/example-185x60-3.jpg'} onClick={this.onClickTest}/>
                    {/* <div className="graphics__nav-item">
                      <Paper className="paper">
                        <div className="graphics__label">Roller-Rotation</div>
                        <div className="graphics__preview">
                          <img src="/assets/images/content/example-185x60-3.jpg" alt="Gap" />
                        </div>
                      </Paper>
                    </div> */}
                    <div className="graphics__nav-item">
                      <Paper className="paper">
                        <div className="graphics__label">Nozzles State</div>
                        <div className="graphics__preview">
                          <img src="/assets/images/content/example-185x60-4.jpg" alt="Gap" />
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    specs: getSegmentsSpecs(store),
    gapData: getGapData(store),
    misalignmentData: getMisalignmentData(store),
  };
};

export const RcTool = connect(mapStateToProps)(_RcTool);
