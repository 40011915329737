import React from 'react';
import PropTypes from 'prop-types';

export class SegmentItem extends React.PureComponent {
  render () {

    const { left, leftClass, center, right, rightClass, width } = this.props;

    return (
      <div className="line-graph__item" style={{width: width ? width + '%' : ''}}>
        <div className={`line-graph__cell ${leftClass}`}>{left}</div>
        <div className="line-graph__cell cell-lg">{center}</div>
        <div className={`line-graph__cell ${rightClass}`}>{right}</div>
      </div>
    );
  }
}

SegmentItem.defaultProps = {
};

SegmentItem.propTypes = {
  left: PropTypes.number,
  center: PropTypes.number,
  right: PropTypes.number,
  width: PropTypes.number,
  leftClass: PropTypes.string,
  rightClass: PropTypes.string,
};
