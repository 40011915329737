import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Specifications } from 'components/specifications';

import { getSegmentsSpecs, getGapData, getMisalignmentData } from 'redux/selectors';

import Plot from 'react-plotly.js';

class _Shims extends React.PureComponent {
  render () {
    const { specs, gapData } = this.props;
    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>
            <div className="grid-default">
              <div className="grid-default__item x12">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-body">
                      <div className="graph-height">
                        <Plot
                          useResizeHandler={true}
                          data={gapData}
                          style={{width: '100%', height: '100%'}}
                          layout={{autosize: true, title: 'Gap'}}
                        />
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    specs: getSegmentsSpecs(store),
    gapData: getGapData(store),
    misalignmentData: getMisalignmentData(store),
  };
};

export const Shims = connect(mapStateToProps)(_Shims);
