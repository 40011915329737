import React from 'react';

export class IconHistory extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 21 18" className="icon icon-history" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M11.9949844,0 C7.02,0 3,4.02998437 3,9 L0,9 L3.89498438,12.8949844 L3.96496875,13.0399688 L8.00001562,9 L5.00001563,9 C5.00001563,5.13501563 8.13501562,2.00001563 12,2.00001563 C15.8649844,2.00001563 18.9999844,5.13501563 18.9999844,9 C18.9999844,12.8649844 15.8649844,15.9999844 12,15.9999844 C10.065,15.9999844 8.31998438,15.21 7.05501563,13.9449844 L5.64,15.36 C7.26501562,16.9899844 9.51,18 11.9949844,18 C16.9700156,18 21,13.9700156 21,9 C21,4.02998438 16.9700156,0 11.9949844,0 Z"></path>
        <polygon points="11 5 11 10.3050176 15.2799955 13 16 11.7108727 12.5000094 9.50926001 12.5000094 5"></polygon>
      </svg>
    );
  }
}
