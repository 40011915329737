import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

export class ErrorControls extends React.PureComponent {
  render () {

    const { selectedItems, onChangeSelection } = this.props;

    return (
      <div className="nav-label">
        <div className="nav-label__item">
          <Button className={classnames('btn btn-red', {'active': selectedItems.gap})} onClick={onChangeSelection.bind(this, 'gap')}>
            <span className="icon-round icon-red">G</span>
        Gap
          </Button>
        </div>
        <div className="nav-label__item">
          <Button className={classnames('btn btn-purple', {'active': selectedItems.misalignment})} onClick={onChangeSelection.bind(this, 'misalignment')}>
            <span className="icon-round icon-purple">M</span>
        Misalignment
          </Button>
        </div>
        <div className="nav-label__item">
          <Button className={classnames('btn btn-yellow', {'active': selectedItems.rotation})} onClick={onChangeSelection.bind(this, 'rotation')}>
            <span className="icon-round icon-yellow">R</span>
        Rotation
          </Button>
        </div>
        <div className="nav-label__item">
          <Button className={classnames('btn btn-blue', {'active': selectedItems.water})} onClick={onChangeSelection.bind(this, 'water')}>
            <span className="icon-round icon-blue">W</span>
        Water
          </Button>
        </div>
      </div>
    );
  }
}

ErrorControls.defaultProps = {
};

ErrorControls.propTypes = {
  selectedItems: PropTypes.object,
};
