import React from 'react';

export class IconTools extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 26 21" className="icon icon-tools" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <polygon points="23.4876777 0 20.0739336 8.09459703 15.3914692 2.68716794 10.5052133 11.9627195 5.59905213 7.22660964 0 19.9060333 2.49336493 21 6.51421801 11.8932238 11.221327 16.4388113 15.9454976 7.47906348 20.8834123 13.1805493 26 1.05425484"></polygon>
      </svg>
    );
  }
}
