import React from 'react';

export class IconNext extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 16 16" className="icon icon-next" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-589.000000, -302.000000)">
            <polygon transform="translate(597.000000, 310.000000) scale(-1, 1) translate(-597.000000, -310.000000)" points="592.8 309 598.4 303.4 597 302 589 310 597 318 598.4 316.6 592.8 311 605 311 605 309"></polygon>
          </g>
        </g>
      </svg>
    );
  }
}
