import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { IconInfo } from 'icons/icon-info';
import { IconSearch } from 'icons/icon-search';
import { Search } from 'components/search';
import { Link } from 'react-router-dom';

export class Header extends React.PureComponent {

  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  _onClose = (open) => {
    if (window.location.pathname === '/search') {
      return;
    }
    this.setState({open});
  }

  render () {
    return (
      <div className="layout-header">
        <div className="container">
          <div className="layout-header__inner">
            <Link className="layout-header__logo" to="/search"></Link>
            <div className="layout-header__panel">
              <IconButton className="btn btn-search" onClick={this.handleClickOpen}>
                <IconSearch />
              </IconButton>
              <IconButton className="btn btn-info">
                <IconInfo />
              </IconButton>
            </div>
          </div>
        </div>

        <Search
          onClose={this._onClose}
          searchState={this.state.open} />
      </div>
    );
  }
}
