import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Specifications } from 'components/specifications';
import { SegmentItem } from 'components/segment-item';
import { SegmentRow } from 'components/segment-row';
import Button from '@material-ui/core/Button';
import { IconNext } from 'icons/icon-next';
import { Link } from 'react-router-dom';
import { getData } from 'api';
import { getSegmentsSpecs } from 'redux/selectors';

class _Lasr extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      loose: [],
      fixed: [],
    };
  }

  componentDidMount () {
    this.fetchData();
  }

  fetchData = async () => {
    const data = await getData();
    this.prepareData(data);
  }

  prepareData = (data) => {
    this.setState({loose: data.SEGMENT.ROLL_LS, fixed: data.SEGMENT.ROLL_FS});
  }

  render () {

    const { loose, fixed } = this.state;
    const { specs } = this.props;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>
            <div className="grid-default">
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">Loose</div>
                      <Button color="primary" component={Link} to="/lasr-open" className="btn btn-primary">
                        more info
                        <IconNext />
                      </Button>
                    </div>
                    <div className="paper-body">
                      <div className="line-graph">
                        {
                          loose.map((element, idx) => {
                            return (
                              <SegmentRow key={idx}>
                                <SegmentItem width={element[0]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-green'}/>
                                <SegmentItem width={element[1]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-red'}/>
                                <SegmentItem width={element[2]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-red'}/>
                              </SegmentRow>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">Fixed</div>
                      <Button color="primary" className="btn btn-primary" component={Link} to="/lasr-open" >
                        more info
                        <IconNext />
                      </Button>
                    </div>
                    <div className="paper-body">
                      <div className="line-graph">
                        {
                          fixed.map((element, idx) => {
                            return (
                              <SegmentRow key={idx}>
                                <SegmentItem width={element[0]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-green'}/>
                                <SegmentItem width={element[1]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-red'}/>
                                <SegmentItem width={element[2]} left={-0.06} center={284.8} right={0.02} leftClass={'cell-green'} rightClass={'cell-red'}/>
                              </SegmentRow>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    specs: getSegmentsSpecs(store),
  };
};

export const Lasr = connect(mapStateToProps)(_Lasr);
