import React from 'react';

export class IconClose extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 17 17" className="icon icon-close" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M10.0034604,8.5001772 L16.6884993,1.8148596 C17.1038336,1.39986241 17.1038336,0.726510872 16.6884993,0.311513686 C16.273165,-0.103837895 15.6005503,-0.103837895 15.1852161,0.311513686 L8.50017719,6.99683129 L1.81478393,0.311513686 C1.39944967,-0.103837895 0.726834963,-0.103837895 0.311500698,0.311513686 C-0.103833566,0.726510872 -0.103833566,1.39986241 0.311500698,1.8148596 L6.99689396,8.5001772 L0.311500698,15.1854948 C-0.103833566,15.600492 -0.103833566,16.2738435 0.311500698,16.6888407 C0.519167831,16.8961621 0.791332263,17 1.06314232,17 C1.33495237,17 1.6071168,16.8961621 1.81478393,16.6884863 L8.50017719,10.0031687 L15.1852161,16.6884863 C15.3928832,16.8961621 15.6650476,17 15.9368577,17 C16.2086677,17 16.4808322,16.8961621 16.6884993,16.6884863 C17.1038336,16.2734891 17.1038336,15.6001376 16.6884993,15.1851404 L10.0034604,8.5001772 Z"></path>
      </svg>
    );
  }
}
