import React from 'react';
import Button from '@material-ui/core/Button';
import { IconState } from 'icons/icon-state';
import { IconHistory } from 'icons/icon-history';
import { IconTools } from 'icons/icon-tools';
import { IconLaser } from 'icons/icon-laser';
import { IconShims } from 'icons/icon-shims';
import { NavLink } from 'react-router-dom';

export class Footer extends React.PureComponent {
  render () {
    return (
      <div className="layout-footer">
        <div className="container">
          <div className="layout-footer__inner">
            <div className="nav-default">
              <div className="nav-default__item">
                <Button size="medium" className="btn btn-default" component={NavLink} to="/state">
                  <IconState />
                  State
                </Button>
              </div>
              <div className="nav-default__item">
                <Button size="medium" className="btn btn-default" component={NavLink} to="/rc-tools">
                  <IconTools />
                  RC-Tools
                </Button>
              </div>
              <div className="nav-default__item">
                <Button size="medium" className="btn btn-default" component={NavLink} to="/lasr">
                  <IconLaser />
                  HDLasr
                </Button>
              </div>
              <div className="nav-default__item">
                <Button size="medium" className="btn btn-default" component={NavLink} to="/shims">
                  <IconShims />
                  Shims
                </Button>
              </div>
              <div className="nav-default__item">
                <Button size="medium" className="btn btn-default" component={NavLink} to="/history">
                  <IconHistory />
                  History
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
