import React from 'react';
import { Preloader } from 'components/preloader';

export class PreloaderLayout extends React.PureComponent {
  render () {
    return (
      <Preloader></Preloader>
    );
  }
}

export default PreloaderLayout;
