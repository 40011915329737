import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SegmentItemSimple } from 'components/segment-item';
import { SegmentRow } from 'components/segment-row';
import { Specifications } from 'components/specifications';
import { ErrorControls } from 'components/error-controls';
import Button from '@material-ui/core/Button';
import { IconNext } from 'icons/icon-next';
import { Link } from 'react-router-dom';
import { fetchSegments } from 'redux/thunks';
import { changeSelectedItems } from 'redux/actions';
import { getSegmentsRolls, getSegmentsSpecs, getSegmentsGaps,
  getSegmentsMisalignments, getSegmentsRotations,
  getSegmentsWaters } from 'redux/selectors';

export class _State extends React.PureComponent {

  componentDidMount () {
    this.props.dispatch(fetchSegments());
  }

  changeSelection = (type) => {
    this.props.dispatch(changeSelectedItems(type));
  }

  render () {

    const { misalignments, rotations, waters, gaps, selectedItems, specs } = this.props;
    const { loose, fixed } = this.props.rolls;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>

            <ErrorControls selectedItems={selectedItems} onChangeSelection={this.changeSelection}/>

            <div className="grid-default">
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">Loose</div>
                      <Button color="primary"
                        className="btn btn-primary"
                        component={Link}
                        to={{
                          pathname: '/state-open',
                          state: {
                            from: 'Loose',
                          }}}>
                        more info
                        <IconNext />
                      </Button>
                    </div>
                    <div className="paper-body">
                      <div className="line-graph">
                        {
                          loose.map((element, idx) => {
                            return (
                              <SegmentRow key={idx}>
                                {
                                  element.map((item, i) => {
                                    return (
                                      <SegmentItemSimple key={i}
                                        selectedItems={selectedItems}
                                        gaps={gaps.length > 0 ? gaps[idx][i] : []}
                                        rotations={rotations.loose.length > 0 ? rotations.loose[idx][i] : []}
                                        waters={waters.loose.length > 0 ? waters.loose[idx][i] : []}
                                        width={item}/>
                                    );
                                  })
                                }
                              </SegmentRow>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">Fixed</div>
                      <Button color="primary"
                        className="btn btn-primary"
                        component={Link}
                        to={{
                          pathname: '/state-open',
                          state: {
                            from: 'Fixed',
                          }}}>
                        more info
                        <IconNext />
                      </Button>
                    </div>
                    <div className="paper-body">
                      <div className="line-graph">
                        {
                          fixed.map((element, idx) => {
                            return (
                              <SegmentRow key={idx}>
                                {
                                  element.map((item, i) => {
                                    return (
                                      <SegmentItemSimple key={i}
                                        selectedItems={selectedItems}
                                        reversed={true}
                                        gaps={gaps.length > 0 ? gaps[idx][i] : []}
                                        misalignments={misalignments.length > 0 ? misalignments[idx][i] : []}
                                        rotations={rotations.fixed.length > 0 ? rotations.fixed[idx][i] : []}
                                        waters={waters.fixed.length > 0 ? waters.fixed[idx][i] : []}
                                        width={item}/>
                                    );
                                  })
                                }
                              </SegmentRow>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rolls: getSegmentsRolls(store),
    specs: getSegmentsSpecs(store),
    gaps: getSegmentsGaps(store),
    misalignments: getSegmentsMisalignments(store),
    rotations: getSegmentsRotations(store),
    waters: getSegmentsWaters(store),
    selectedItems: store.selectedItems,
  };
};

export const State = connect(mapStateToProps)(_State);
