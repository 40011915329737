import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

export class PlotNavButton extends React.PureComponent {
  render () {

    const { label, imageSrc, onClick } = this.props;

    return (
      <div className="graphics__nav-item" onClick={onClick} style={{cursor: 'pointer'}}>
        <Paper className="paper">
          <div className="graphics__label">{label}</div>
          <div className="graphics__preview">
            <img src={imageSrc} alt={label} />
          </div>
        </Paper>
      </div>
    );
  }
}

PlotNavButton.defaultProps = {
};

PlotNavButton.propTypes = {
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};
