import React from 'react';

export class Preloader extends React.PureComponent {
  render () {
    return (
      <div className="layout-preloader">
        <div className="loader">
          <div className="loader__item-1"></div>
          <div className="loader__item-2"></div>
        </div>
      </div>
    );
  }
}
