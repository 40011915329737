import React from 'react';
import PropTypes from 'prop-types';

export const LoginLayout = ({children}) =>
  <div className="section section-login">
    {children}
  </div>;

LoginLayout.defaultProps = {

};

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
