import React from 'react';

export class IconInfo extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 26 26" className="icon icon-info" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-1223.000000, -11.000000)">
            <g id="information" transform="translate(1223.000000, 11.000000)">
              <path d="M13,0 C5.8318,0 0,5.83187879 0,13.0000788 C0,20.1682788 5.8318,26 13,26 C20.1682,26 26,20.1682788 26,13.0000788 C26,5.83187879 20.1682,0 13,0 Z M13,23.6363636 C7.1350303,23.6363636 2.36363636,18.8649697 2.36363636,13.0000788 C2.36363636,7.13518788 7.1350303,2.36363636 13,2.36363636 C18.8649697,2.36363636 23.6363636,7.13518788 23.6363636,13.0000788 C23.6363636,18.8649697 18.8648909,23.6363636 13,23.6363636 Z" fillRule="nonzero"></path>
              <path d="M13.5,6 C12.6728846,6 12,6.6732 12,7.500675 C12,8.3274 12.6728846,9 13.5,9 C14.3271154,9 15,8.3274 15,7.500675 C15,6.6732 14.3271154,6 13.5,6 Z"></path>
              <path d="M13.5,11 C12.6716,11 12,11.5037 12,12.125 L12,18.875 C12,19.4963 12.6716,20 13.5,20 C14.3284,20 15,19.4963 15,18.875 L15,12.125 C15,11.5037 14.3284,11 13.5,11 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
