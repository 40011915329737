import React from 'react';

export class IconLaser extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 19 19" className="icon icon-laser" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M11,10.2483224 L18.7035078,7.13590319 C18.897079,7.89171651 19,8.68383901 19,9.5 C19,14.2363037 15.5339785,18.1630746 11,18.8822483 L11,10.2483224 Z M9,18.9870679 C3.98571764,18.7270615 0,14.5789879 0,9.5 C0,8.56845656 0.134078271,7.66822878 0.383989489,6.81756198 L9,10.2986562 L9,18.9870679 Z M9.93770972,8.52044603 L1.14799309,4.96916999 C2.75713459,2.00918522 5.89393212,0 9.5,0 C13.2234968,0 16.4466627,2.14217102 18.0043119,5.26132721 L9.93770972,8.52044603 Z"></path>
      </svg>
    );
  }
}
