
import React from 'react';
import {Route, Router, Switch} from 'react-router';
import {Redirect} from 'react-router-dom';
import {
  Login,
  DefaultLayout,
  LoginLayout,
  PreloaderLayout,
  History,
  Lasr,
  LasrOpen,
  LayoutSearch,
  State,
  StateOpen,
  RcTool,
  Shims,
} from 'scenes';
import createHistory from 'history/createBrowserHistory';

const history = createHistory({});

export default ({store}) => {
  return (
    <Router history={history}>
      <AuthWrapper store={store}/>
    </Router>
  );
};

const AuthWrapper = ({store}) => {
  const data = store.getState();
  const authorized = data.token.length > 0;
  if (authorized)
    return (
      <Switch>
        <Route path='/rc-tools' component={wrapperRcTool}/>
        <Route path='/preloader' component={wrapperPreloader}/>
        <Route path='/history' component={wrapperHistory}/>
        <Route path='/search' component={wrapperLayoutSearch}/>
        <Route path='/state' component={wrapperLayoutState}/>
        <Route path='/state-open' component={wrapperStateOpen}/>
        <Route path='/shims' component={wrapperShims}/>
        <Route path='/lasr' component={wrapperLasr}/>
        <Route path='/lasr-open' component={wrapperLasrOpen}/>
        <Redirect form="/*" to={'/search'} />
      </Switch>
    );
  return (
    <Switch>
      <Route path='/login' component={wrapperLogin}/>
      <Redirect form="/*" to={'/login'} />
    </Switch>
  );
};

const wrapperLogin = ({match}) => (
  <LoginLayout>
    <Switch>
      <Route path={`${match.url}`} component={Login}/>
    </Switch>
  </LoginLayout>
);

const wrapperHistory = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={History}/>
  </Switch>
);

const wrapperLasr = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={Lasr}/>
  </Switch>
);

const wrapperLasrOpen = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={LasrOpen}/>
  </Switch>
);

const wrapperLayoutSearch = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={LayoutSearch}/>
  </Switch>
);

const wrapperLayoutState = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={State}/>
  </Switch>
);

const wrapperStateOpen = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={StateOpen}/>
  </Switch>
);

const wrapperRcTool = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={RcTool}/>
  </Switch>
);

const wrapperShims = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={Shims}/>
  </Switch>
);

const wrapperPreloader = ({match}) => (
  <DefaultLayout>
    <Switch>
      <Route path={`${match.url}`} component={PreloaderLayout}/>
    </Switch>
  </DefaultLayout>
);
