import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './styles/app.scss';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import Routes from './app.routes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009cff',
      contrastText: '#fff',
    },
  },
  typography: { useNextVariants: true },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Routes store={store}/>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root'));
registerServiceWorker();
