import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LabelField } from 'components/label-field';

export class Specifications extends React.PureComponent {

    state = {
      selectedDate: new Date('2019-01-05'),
    };

      handleDateChange = date => {
        this.setState({ selectedDate: date });
      };

      render () {
        const { specs } = this.props;
        const { selectedDate } = this.state;
        return (
          <div className="grid-default">
            <div className="grid-default__item x12">
              <Paper className="paper">
                <div className="paper-inner">
                  <div className="specifications">
                    <div className="specifications__preview">
                      <img alt="" src="/assets/images/content/example-209x134-1.jpg" className="specifications__image" />
                    </div>
                    <div className="specifications__panel">
                      <div className="grid-default">
                        <div className="grid-default__item x4">
                          <div className="list-info">
                            <LabelField label={'Segment:'} field={'1.8.5'}/>
                            <LabelField label={'Type:'} field={specs.SegType}/>
                          </div>
                        </div>
                        <div className="grid-default__item x4">
                          <div className="list-info">
                            <LabelField label={'Caster:'} field={specs.CASTER}/>
                            <LabelField label={'Position:'} field={specs.Position}/>
                          </div>
                        </div>
                        <div className="grid-default__item x4">
                          <div className="list-info">
                            <LabelField label={'Installed:'} field={specs.INSTALLED}/>
                            <div className="list-info__item">
                              <div className="list-info__label">Measurement:</div>
                              <div className="list-info__body">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    className="datepicker-default"
                                    margin="normal"
                                    format="dd.MM.yyyy"
                                    value={selectedDate}
                                    onChange={this.handleDateChange}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        );
      }
}
