import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { Specifications } from 'components/specifications';
import Button from '@material-ui/core/Button';
import { IconClose } from 'icons/icon-close';
import { Link } from 'react-router-dom';
import { getSegmentsSpecs } from 'redux/selectors';

class _LasrOpen extends React.PureComponent {
  render () {

    const { specs } = this.props;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>
            <div className="grid-default">
              <div className="grid-default__item x12">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">Loose</div>
                      <Button className="btn btn-close" component={Link} to="/lasr" >
                        <IconClose />
                      </Button>
                    </div>
                    <div className="paper-body">

                      <div className="section-lasr">
                        <div className="section-lasr__header">
                          <div className="section-lasr__label">Left</div>
                          <div className="section-lasr__center">
                            <div className="section-lasr__directions">OUTPUT</div>
                            <div className="section-lasr__label">Center</div>
                            <div className="section-lasr__description">
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                            </div>
                          </div>
                          <div className="section-lasr__label">Right</div>
                        </div>
                        <div className="section-lasr__body">
                          <div className="section-lasr__grid">
                            <div className="section-lasr__aside">
                              <div className="boxes boxes_left">
                                <div className="boxes__line">
                                  <div className="box box-s4">
                                    <div className="box__label">S4</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g3">
                                    <div className="box__label">G3</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g2">
                                    <div className="box__label">G2</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g1">
                                    <div className="box__label">G1</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-s2">
                                    <div className="box__label">S2</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="section-lasr__panel">
                              <div className="line-graph">
                                <div className="line-graph__row">
                                  <span className="line-graph__number">7</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">6</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.80</div>
                                    <div className="line-graph__cell cell-red">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">5</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">4</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">3</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">2</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.80</div>
                                    <div className="line-graph__cell cell-red">0.02</div>
                                  </div>
                                </div>
                                <div className="line-graph__row">
                                  <span className="line-graph__number">1</span>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-red">0.02</div>
                                  </div>
                                  <div className="line-graph__item">
                                    <div className="line-graph__cell cell-green">-0.06</div>
                                    <div className="line-graph__cell cell-lg">284.8</div>
                                    <div className="line-graph__cell cell-green">0.02</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="section-lasr__aside">
                              <div className="boxes boxes_right">
                                <div className="boxes__line">
                                  <div className="box box-s3">
                                    <div className="box__label">S3</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g6">
                                    <div className="box__label">G6</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g5">
                                    <div className="box__label">G5</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-g4">
                                    <div className="box__label">G4</div>
                                  </div>
                                </div>
                                <div className="boxes__line">
                                  <div className="box box-s1">
                                    <div className="box__label">S1</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="section-lasr__footer">
                          <div className="section-lasr__label">Tolerance: 0.1</div>
                          <div className="section-lasr__center">
                            <div className="section-lasr__directions">INPUT</div>
                          </div>
                          <div className="section-lasr__label">Torsion: 0.1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    specs: getSegmentsSpecs(store),
  };
};

export const LasrOpen = connect(mapStateToProps)(_LasrOpen);
