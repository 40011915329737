import * as segment from './data/segment.json';
import * as sensors from './data/sensors.json';
import * as full from './data/full.json';
import * as search from './data/search.json';

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

export const login = (username, password) => {
  return fetch('http://10.0.1.31:9090/api/auth/login', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      username,
      password,
    }),
  });
};

export const getSegmentData = () => {
  return new Promise((resolve) => {
    resolve(segment.default);
  });
};

export const getSegmentSensors = () => {
  return new Promise((resolve) => {
    resolve(sensors.default);
  });
};

export const getData = () => {
  return new Promise((resolve) => {
    resolve(full.default);
  });
};

export const getDataSearch = () => {
  return new Promise((resolve) => {
    resolve(search.default);
  });
};
