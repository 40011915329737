import React from 'react';
import PropTypes from 'prop-types';

export class LabelField extends React.PureComponent {
  render () {

    const { label, field } = this.props;

    return (
      <div className="list-info__item">
        <div className="list-info__label">{label}</div>
        <div className="list-info__body">{field}</div>
      </div>
    );
  }
}

LabelField.defaultProps = {
};

LabelField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.any,
};
