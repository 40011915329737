import React from 'react';
import PropTypes from 'prop-types';

const offset = 5;

export class SegmentItemSimple extends React.PureComponent {
  render () {

    const { width, reversed, gaps, misalignments, rotations, waters, selectedItems } = this.props;

    return (
      <div className="line-graph__item" style={{width: width ? width + '%' : ''}}>
        {
          gaps && selectedItems.gap && gaps.map((gap, idx) => {
            const _gap = reversed ? 100 - gap - offset : gap - offset;
            return (
              <div key={idx} className="line-graph__cell" style={{left: _gap + '%', position: 'absolute'}}>
                <span className="icon-round icon-red">G</span>
              </div>

            );
          })
        }
        {
          misalignments && selectedItems.misalignment && misalignments.map((misalignment, idx) => {
            return (
              <div key={idx} className="line-graph__cell" style={{left: (misalignment - offset) + '%', position: 'absolute'}}>
                <span className="icon-round icon-purple">M</span>
              </div>

            );
          })
        }
        {
          rotations && selectedItems.rotation && rotations.map((rotation, idx) => {
            return (
              <div key={idx} className="line-graph__cell" style={{left: (rotation - offset) + '%', position: 'absolute'}}>
                <span className="icon-round icon-yellow">R</span>
              </div>

            );
          })
        }
        {
          waters && selectedItems.water && waters.map((water, idx) => {
            return (
              <div key={idx} className="line-graph__cell" style={{left: (water - offset) + '%', position: 'absolute'}}>
                <span className="icon-round icon-blue">W</span>
              </div>

            );
          })
        }
      </div>
    );
  }
}

SegmentItemSimple.defaultProps = {
};

SegmentItemSimple.propTypes = {
  width: PropTypes.number,
  reversed: PropTypes.bool,
  selectedItems: PropTypes.object,
  gaps: PropTypes.array,
  misalignments: PropTypes.array,
  rotations: PropTypes.array,
  waters: PropTypes.array,
};
