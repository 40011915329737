import React from 'react';
import PropTypes from 'prop-types';

export const DefaultLayout = ({children}) =>
  <div className="layout-body">
    {children}
  </div>;

DefaultLayout.defaultProps = {

};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
