import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { segmentsReducer } from './segments/segments.reducer';
import { selectedItemsReducer } from './selected-items/selected-items.reducer';

export default combineReducers({
  token: authReducer,
  segments: segmentsReducer,
  selectedItems: selectedItemsReducer,
});
