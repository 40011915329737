import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { login } from 'api';
import { setToken } from 'redux/actions';
// import { Link } from 'react-router-dom';

class _Login extends React.PureComponent {

    state = {
      email: '',
      password: '',
      remember: true,
    }

    // componentDidMount () {
    //   var request = window.indexedDB.open('EXAMPLE_DB', 1);

    //   request.onerror = function (event) {
    //     console.log('[onerror]', request.error, event);
    //   };

    //   request.onupgradeneeded = function (event) {
    //     var db = event.target.result;
    //     var productsStore = db.createObjectStore('products', {keyPath: 'id'});
    //     console.log(productsStore);
    //   };

    //   request.onsuccess = function (event) {
    //     var products = [
    //       {id: 1, name: 'Red Men T-Shirt', price: '$3.99'},
    //       {id: 2, name: 'Pink Women Shorts', price: '$5.99'},
    //       {id: 3, name: 'Nike white Shoes', price: '$300'}
    //     ];
    //     var db = event.target.result;
    //     var transaction = db.transaction('products', 'readwrite');

    //     transaction.onsuccess = function (event) {
    //       console.log('[Transaction] ALL DONE!', event);
    //     };

    //     var productsStore = transaction.objectStore('products');

    //     products.forEach(function (product) {
    //       var db_op_req = productsStore.add(product);
    //       console.log(db_op_req);
    //     });
    //   };

    //   productsStore.get(1).onsuccess = function (event) {
    //     console.log('[Transaction - GET] product with id 1', event.target.result);
    //   };
    // }

    handleChange = name => event => {
      this.setState({ [name]: event.target.value });
    };

    handleRemember = () => {
      this.setState({remember: !this.state.remember});
    }

    onLoginPress = async () => {
      const { dispatch } = this.props;
      await dispatch(setToken('testtoken'));
      this.props.history.push('/search');
    //   const { email, password } = this.state;
    //   const { dispatch } = this.props;
    //   const result = await login(email, password);
    //   if (result.status === 200) {
    //     const data = await result.json();
    //     await dispatch(setToken(data.token));
    //     this.props.history.push('/state');
    //   }
    }

    render () {

      const { email, password, remember } = this.state;

      return (
        <div className="section-login__inner">
          <CssBaseline />
          <div className="section-login__head">
            <div className="section-login__logo"></div>
            <div className="section-login__note">Segment Advisor</div>
          </div>
          <Paper className="section-login__panel">
            <Typography component="h1" variant="h5">
            Login
            </Typography>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" autoComplete="email" value={email} autoFocus onChange={this.handleChange('email')}/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" value={password} autoComplete="current-password" onChange={this.handleChange('password')}/>
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" checked={remember} onClick={this.handleRemember} color="primary" />}
                label="Remember me"
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.onLoginPress}
                className="btn btn-light"
              >
              Sign in
              </Button>
            </form>
          </Paper>
        </div>
      );
    }
}

export const Login = connect()(_Login);
