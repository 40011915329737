import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';

export class Segment extends React.PureComponent {
  render () {
    return (
      <div className="layout-inner">
        <Header />

        <div className="layout-body">
          <div className="container">

            <div className="grid-default">
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">

                  </div>
                </Paper>
              </div>

              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">

                  </div>
                </Paper>
              </div>
            </div>

            <Paper className="paper">
              <div className="paper-inner">

              </div>
            </Paper>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Segment;
