import { createSelector } from 'reselect';
import { colors } from 'consts';

const _getSegmnents = (state) => state.segments;
const _getSearch = (state) => state.search;

export const getSearch = createSelector(
  [_getSearch],
  searchs => searchs
);

export const getSegments = createSelector(
  [_getSegmnents],
  segments => segments
);

export const getSegmentsRolls = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      return {
        loose: segments.SEGMENT.ROLL_LS,
        fixed: segments.SEGMENT.ROLL_LS, // symmetric
      };
    }
    return {
      loose: [],
      fixed: [],
    };
  }
);

export const getSegmentsSpecs = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      return segments.SEGMENT;
    }
    return {};
  }
);

export const getSegmentsGaps = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      const { GAP_ERROR } = segments.MEASUREMENT_SEGMENT_DATA;
      const { GAP_TOLERANCE } = segments.RGC_DATA;
      const gapMin = GAP_TOLERANCE[0];
      const gapMax = GAP_TOLERANCE[1];
      const gaps = [];
      segments.SEGMENT.ROLL_LS.forEach((roll, i) => {
        let pos = 0;
        const rollGaps = [];
        roll.forEach((width) => {
          const itemGaps = [];
          segments.RGC_DATA.GAP_SENSOR.forEach((gap, j) => {
            if (gap <= pos + width && gap >= pos) {
              if (GAP_ERROR[i][j] < gapMin || GAP_ERROR[i][j] > gapMax) {
                itemGaps.push(gap);
              }
            }
          });
          pos += width;
          rollGaps.push(itemGaps);
        });
        gaps.push(rollGaps);
      });
      return gaps;
    }
    return [];
  }
);

export const getSegmentsMisalignments = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      const { MISALIGNMENT_ERROR_FS } = segments.MEASUREMENT_SEGMENT_DATA;
      const { MISALIGNMENT_TOLERANCE } = segments.RGC_DATA;
      const malMin = MISALIGNMENT_TOLERANCE[0];
      const malMax = MISALIGNMENT_TOLERANCE[1];
      const misalignmentsFS = [];
      segments.SEGMENT.ROLL_FS.forEach((roll, i) => {
        let pos = 0;
        const rollMisalignmentsFS = [];
        roll.forEach((width) => {
          const itemMisalignmentsFS = [];
          segments.RGC_DATA.MISALIGNMENT_SENSOR.forEach((misaligment, j) => {
            if (misaligment <= pos + width && misaligment >= pos) {
              if (MISALIGNMENT_ERROR_FS[i][j] < malMin || MISALIGNMENT_ERROR_FS[i][j] > malMax) {
                itemMisalignmentsFS.push(misaligment);
              }
            }
          });
          pos += width;
          rollMisalignmentsFS.push(itemMisalignmentsFS);
        });
        misalignmentsFS.push(rollMisalignmentsFS);
      });
      return misalignmentsFS;
    }
    return [];
  });

export const getSegmentsRotations = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      const { ROTATION_ERROR_LS, ROTATION_ERROR_FS } = segments.MEASUREMENT_SEGMENT_DATA;
      const { ROTATION_THRESHOLD } = segments.RGC_DATA;
      const rotationsLS = [];
      const rotationsFS = [];
      segments.SEGMENT.ROLL_LS.forEach((roll, i) => {
        let pos = 0;
        const rollRotationsLS = [];
        roll.forEach((width) => {
          const itemRotationsLS = [];
          segments.RGC_DATA.ROTATION_SENSOR.forEach((rotation, j) => {
            if (rotation <= pos + width && rotation >= pos) {
              if (ROTATION_ERROR_LS[i][j] > ROTATION_THRESHOLD) {
                itemRotationsLS.push(rotation);
              }
            }
          });
          pos += width;
          rollRotationsLS.push(itemRotationsLS);
        });
        rotationsLS.push(rollRotationsLS);
      });
      segments.SEGMENT.ROLL_FS.forEach((roll, i) => {
        let pos = 0;
        const rollRotationsFS = [];
        roll.forEach((width) => {
          const itemRotationsFS = [];
          segments.RGC_DATA.ROTATION_SENSOR.forEach((rotation, j) => {
            if (rotation <= pos + width && rotation >= pos) {
              if (ROTATION_ERROR_FS[i][j] > ROTATION_THRESHOLD) {
                itemRotationsFS.push(rotation);
              }
            }
          });
          pos += width;
          rollRotationsFS.push(itemRotationsFS);
        });
        rotationsFS.push(rollRotationsFS);
      });
      return {loose: rotationsLS, fixed: rotationsFS};
    }
    return {loose: [], fixed: []};
  });

export const getSegmentsWaters = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      const { WATER_ERROR_LS, WATER_ERROR_FS } = segments.MEASUREMENT_SEGMENT_DATA;
      const { WATER_THRESHOLD } = segments.RGC_DATA;
      const watersLS = [];
      const watersFS = [];
      segments.SEGMENT.ROLL_LS.forEach((roll, i) => {
        let pos = 0;
        const rollWatersLS = [];
        roll.forEach((width) => {
          const itemWatersLS = [];
          segments.RGC_DATA.WATER_SENSOR.forEach((rotation, j) => {
            if (rotation <= pos + width && rotation >= pos) {
              if (WATER_ERROR_LS[i][j] > WATER_THRESHOLD) {
                itemWatersLS.push(rotation);
              }
            }
          });
          pos += width;
          rollWatersLS.push(itemWatersLS);
        });
        watersLS.push(rollWatersLS);
      });
      segments.SEGMENT.ROLL_FS.forEach((roll, i) => {
        let pos = 0;
        const rollWatersFS = [];
        roll.forEach((width) => {
          const itemWatersFS = [];
          segments.RGC_DATA.WATER_SENSOR.forEach((rotation, j) => {
            if (rotation <= pos + width && rotation >= pos) {
              if (WATER_ERROR_FS[i][j] > WATER_THRESHOLD) {
                itemWatersFS.push(rotation);
              }
            }
          });
          pos += width;
          rollWatersFS.push(itemWatersFS);
        });
        watersFS.push(rollWatersFS);
      });
      return {loose: watersLS, fixed: watersFS};
    }
    return {loose: [], fixed: []};
  });

export const getGapData = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      return segments.MEASUREMENT_FULL_DATA.GAP_DATA.map((line, i) => {
        const gapDataX = line.map((dot, j) => {
          return j;
        });

        return {
          x: gapDataX,
          y: line,
          type: 'scatter',
          name: i + 1,
          mode: 'lines+points',
          marker: {color: colors[i]},
        };
      });
    }
    return [];
  }
);

export const getMisalignmentData = createSelector(
  [_getSegmnents],
  segments => {
    if (segments) {
      return segments.MEASUREMENT_FULL_DATA.MISALIGNMENT_DATA.map((line, i) => {
        const gapDataX = line.map((dot, j) => {
          return j;
        });

        return {
          x: gapDataX,
          y: line,
          type: 'scatter',
          name: i + 1,
          mode: 'lines+points',
          marker: {color: colors[i]},
        };
      });
    }
    return [];
  }
);
