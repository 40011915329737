import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { getSegmentsSpecs } from 'redux/selectors';
import { Specifications } from 'components/specifications';
import { Header } from 'components/header';
import { Footer } from 'components/footer';

let counter = 0;
function createData (cell_caster, cell_stand, cell_position, cell_installed, cell_deinstalled, cell_period, cell_in, cell_out) {
  counter += 1;
  return { id: counter, cell_caster, cell_stand, cell_position, cell_installed, cell_deinstalled, cell_period, cell_in, cell_out };
}

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting (order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rowsHeadTable = [
  { id: 'cell_caster', numeric: false, label: 'Caster' },
  { id: 'cell_stand', numeric: true, label: 'Stand' },
  { id: 'cell_position', numeric: true, label: 'Position' },
  { id: 'cell_installed', numeric: true, label: 'Installed' },
  { id: 'cell_deinstalled', numeric: true, label: 'Deinstalled' },
  { id: 'cell_period', numeric: true, label: 'Castlength per period/m' },
  { id: 'cell_in', numeric: true, label: 'Charge No. In' },
  { id: 'cell_out', numeric: true, label: 'Charge No. Out' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render () {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rowsHeadTable.map(
            row => (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

class _History extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    data: [
      createData('SGA1', 1, 2, '09.07.2018', '-', 434, 17479, '-'),
      createData('SGA2', 2, 3, '25.02.2018', '01.04.2018', 113920, 12591, 14568),
      createData('SGA3', 3, 4, '08.07.2017', '11.10.2017', 153920, 5973, 9566),
      createData('SGA4', 4, 5, '13.01.2017', '21.04.2017', 123920, 408, 2565),
      createData('SGA5', 5, 6, '08.07.2017', '11.10.2017', 153920, 5973, 9566),
      createData('SGA6', 6, 7, '08.07.2017', '11.10.2017', 153920, 5973, 9566),
      createData('SGA7', 7, 8, '13.01.2017', '21.04.2017', 123920, 408, 2565),
    ],
    page: 0,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render () {
    const { data, order, orderBy, rowsPerPage, page } = this.state;
    const { specs } = this.props;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>

            <Paper className="paper">
              <div className="table-autoscroll">
                <Table className="table-default">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(n => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={n.id}
                          >
                            <TableCell>{n.cell_caster}</TableCell>
                            <TableCell>{n.cell_stand}</TableCell>
                            <TableCell>{n.cell_position}</TableCell>
                            <TableCell>{n.cell_installed}</TableCell>
                            <TableCell>{n.cell_deinstalled}</TableCell>
                            <TableCell>{n.cell_period}</TableCell>
                            <TableCell>{n.cell_in}</TableCell>
                            <TableCell>{n.cell_out}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    specs: getSegmentsSpecs(store),
  };
};

export const History = connect(mapStateToProps)(_History);
