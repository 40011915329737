export const initState = {
  token: '',
  segments: null,
  selectedItems: {
    gap: true,
    misaligment: false,
    rotation: false,
    water: false,
  },
};
