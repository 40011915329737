import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SegmentItemSimple } from 'components/segment-item';
import { SegmentRow } from 'components/segment-row';
import { Specifications } from 'components/specifications';
import { ErrorControls } from 'components/error-controls';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormGroup from '@material-ui/core/FormGroup';
import { IconClose } from 'icons/icon-close';
import { Link } from 'react-router-dom';
import { changeSelectedItems } from 'redux/actions';
import { getSegmentsRolls, getSegmentsSpecs, getSegmentsGaps,
  getSegmentsMisalignments, getSegmentsRotations,
  getSegmentsWaters } from 'redux/selectors';

const LOOSE = 'Loose';

export class _StateOpen extends React.PureComponent {

  changeSelection = (type) => {
    this.props.dispatch(changeSelectedItems(type));
  }

  render () {

    const isLoose = this.props.location.state.from === LOOSE;

    const { misalignments, rotations, waters, gaps, selectedItems, specs } = this.props;
    const { loose, fixed } = this.props.rolls;

    const _rotations = isLoose ? rotations.loose : rotations.fixed;
    const _waters = isLoose ? waters.loose : waters.fixed;

    const side = isLoose ? loose : fixed;

    return (
      <div className="layout-inner">
        <Header />
        <div className="layout-body">
          <div className="container">
            <Specifications specs={specs}/>
            <ErrorControls selectedItems={selectedItems} onChangeSelection={this.changeSelection}/>
            <div className="grid-default">
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <div className="paper-title">{this.props.location.state.from}</div>
                    </div>
                    <div className="paper-body">
                      <div className="line-graph">
                        {
                          side.map((element, idx) => {
                            return (
                              <SegmentRow key={idx}>
                                {
                                  element.map((item, i) => {
                                    return (
                                      <SegmentItemSimple key={i}
                                        selectedItems={selectedItems}
                                        reversed={!isLoose}
                                        gaps={gaps.length > 0 ? gaps[idx][i] : []}
                                        misalignments={misalignments.length > 0 ? misalignments[idx][i] : []}
                                        rotations={_rotations.length > 0 ? _rotations[idx][i] : []}
                                        waters={_waters.length > 0 ? _waters[idx][i] : []}
                                        width={item}/>
                                    );
                                  })
                                }
                              </SegmentRow>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="grid-default__item x6">
                <Paper className="paper">
                  <div className="paper-inner">
                    <div className="paper-head">
                      <Button className="btn btn-close" component={Link} to="/state">
                        <IconClose />
                      </Button>
                    </div>
                    <div className="paper-body">
                      <div className="accordion-autoscroll">
                        <ExpansionPanel className="panel-default">
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className="panel-default__title">Roller 2</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Roller-Rotation Sensor-1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                            </FormGroup>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="panel-default">
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className="panel-default__title">Roller 3</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Roller-Rotation Sensor-1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                            </FormGroup>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="panel-default">
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className="panel-default__title">Roller 4</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Roller-Rotation Sensor-1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                            </FormGroup>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel className="panel-default">
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className="panel-default__title">Roller 5</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Roller-Rotation Sensor-1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Roller-Rotation Sensor-1"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Misalignment, left, Step to R3 (0.5 mm)"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Gap out off Tolerance (-1.8 mm)"
                              />
                            </FormGroup>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rolls: getSegmentsRolls(store),
    specs: getSegmentsSpecs(store),
    gaps: getSegmentsGaps(store),
    misalignments: getSegmentsMisalignments(store),
    rotations: getSegmentsRotations(store),
    waters: getSegmentsWaters(store),
    selectedItems: store.selectedItems,
  };
};

export const StateOpen = connect(mapStateToProps)(_StateOpen);
