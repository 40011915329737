import React from 'react';

export class IconSearch extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 26 26" className="icon icon-search" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-1166.000000, -11.000000)" fillRule="nonzero">
            <g transform="translate(1166.000000, 11.000000)">
              <path d="M18.5714286,16.3428571 L17.3828571,16.3428571 L16.9372011,15.8971429 C18.4229154,14.2628571 19.314344,12.0342857 19.314344,9.65714286 C19.3142857,4.30857143 15.0057143,0 9.65714286,0 C4.30857143,0 0,4.30857143 0,9.65714286 C0,15.0057143 4.30857143,19.3142857 9.65714286,19.3142857 C12.0342857,19.3142857 14.2628571,18.4228571 15.8971429,16.9371429 L16.3428571,17.3827989 L16.3428571,18.5713703 L23.7714286,26 L26,23.7714286 L18.5714286,16.3428571 Z M9.65714286,16.3428571 C5.94285714,16.3428571 2.97142857,13.3714286 2.97142857,9.65714286 C2.97142857,5.94285714 5.94285714,2.97142857 9.65714286,2.97142857 C13.3714286,2.97142857 16.3428571,5.94285714 16.3428571,9.65714286 C16.3428571,13.3714286 13.3714286,16.3428571 9.65714286,16.3428571 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
