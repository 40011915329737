import React from 'react';
import PropTypes from 'prop-types';

export class SegmentRow extends React.PureComponent {
  render () {

    const { children } = this.props;

    return (
      <div className="line-graph__row">
        {children}
      </div>
    );
  }
}

SegmentRow.defaultProps = {
};

SegmentRow.propTypes = {
  children: PropTypes.node.isRequired,
};
