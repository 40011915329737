import React from 'react';

export class IconState extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 20 15" className="icon icon-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M19.5,3.5 C19.5,5.15685425 18.1568542,6.5 16.5,6.5 L3.5,6.5 C1.84314575,6.5 0.5,5.15685425 0.5,3.5 C0.5,1.84314575 1.84314575,0.5 3.5,0.5 L16.5,0.5 C18.1568542,0.5 19.5,1.84314575 19.5,3.5 Z M15.5,5 C16.3284271,5 17,4.32842712 17,3.5 C17,2.67157288 16.3284271,2 15.5,2 C14.6715729,2 14,2.67157288 14,3.5 C14,4.32842712 14.6715729,5 15.5,5 Z"></path>
        <g transform="translate(0.000000, 8.000000)">
          <path d="M19.5,3.5 C19.5,5.15685425 18.1568542,6.5 16.5,6.5 L3.5,6.5 C1.84314575,6.5 0.5,5.15685425 0.5,3.5 C0.5,1.84314575 1.84314575,0.5 3.5,0.5 L16.5,0.5 C18.1568542,0.5 19.5,1.84314575 19.5,3.5 Z M15.5,5 C16.3284271,5 17,4.32842712 17,3.5 C17,2.67157288 16.3284271,2 15.5,2 C14.6715729,2 14,2.67157288 14,3.5 C14,4.32842712 14.6715729,5 15.5,5 Z"></path>
        </g>
      </svg>
    );
  }
}
