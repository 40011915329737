import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header } from 'components/header';
import { Search } from 'components/search';
import { fetchSearch } from 'redux/thunks';

export class _LayoutSearch extends React.PureComponent {
  state = {
    open: true,
  };
  _onClose = (open) => {
    if (window.location.pathname === '/search') {
      return;
    }
    this.setState({open});
  }
  _onPush = () => {
    this.props.history.push('/state');
  }

  componentDidMount () {
    //this.props.dispatch(fetchSearch());
    const { dispatch } = this.props;
    dispatch(fetchSearch());
  }

  render () {
    const { search, rotations } = this.props;
    console.log(rotations, 'info');
    return (
      <div className="layout-inner">
        <Header searchState={this.state.open} />
        <Search
          search={search}
          onClose={this._onClose}
          onPush={this._onPush}
          searchState={this.state.open} />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
    dispatch: PropTypes.func,
  };
};

export const LayoutSearch = connect(mapStateToProps)(_LayoutSearch);

