import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

export class Search extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      obj1: '',
      obj2: '',
      obj3: '',
      obj4: '',
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: Number(event.target.value) });
  };

  handleClose = () => {
    const { onClose, searchState } = this.props;
    if (onClose) {
      onClose(!searchState);
    }
  }

  onSelect = () => {
    this.handleClose();
    if (this.props.onPush) {
      this.props.onPush();
    }
  }

  render () {
    const isOpen = this.props.searchState;
    //const { search } = this.props;

    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogContent className="dialog-wrapper">
            <div className="module-search">
              <Grid
                container
                spacing={0}
                className="grid-primary"
                alignItems="center"
              >
                <Grid item xs={2} className="grid-primary__item">
                  <div className="form-label">
                    Segment:
                  </div>
                </Grid>
                <Grid item xs={2} className="grid-primary__item">
                  <FormControl className="form-control">
                    <Select
                      value={this.state.obj1}
                      onChange={this.handleChange('obj1')}
                      displayEmpty
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Caster
                      </MenuItem>
                      <MenuItem value={1}>Caster 1</MenuItem>
                      <MenuItem value={2}>Caster 2</MenuItem>
                      <MenuItem value={3}>Caster 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} className="grid-primary__item">
                  <FormControl className="form-control">
                    <Select
                      value={this.state.obj2}
                      onChange={this.handleChange('obj2')}
                      displayEmpty
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                      Type
                      </MenuItem>
                      <MenuItem value={1}>Type 1</MenuItem>
                      <MenuItem value={2}>Type 2</MenuItem>
                      <MenuItem value={3}>Type 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} className="grid-primary__item">
                  <FormControl className="form-control">
                    <Select
                      value={this.state.obj3}
                      onChange={this.handleChange('obj3')}
                      displayEmpty
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Nr.
                      </MenuItem>
                      <MenuItem value={1}>Nr. 1</MenuItem>
                      <MenuItem value={2}>Nr. 2</MenuItem>
                      <MenuItem value={3}>Nr. 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} className="grid-primary__item">
                  <FormControl className="form-control">
                    <Select
                      value={this.state.obj4}
                      onChange={this.handleChange('obj4')}
                      displayEmpty
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Position
                      </MenuItem>
                      <MenuItem value={1}>Position 1</MenuItem>
                      <MenuItem value={2}>Position 2</MenuItem>
                      <MenuItem value={3}>Position 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} className="grid-primary__item">
                  <Button variant="contained" color="primary" onClick={this.onSelect} className="btn btn-full">
                    Select
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default Search;
