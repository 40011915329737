import React from 'react';

export class IconShims extends React.PureComponent {
  render () {
    return (
      <svg viewBox="0 0 28 18" className="icon icon-shims" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect id="Rectangle" transform="translate(9.671970, 8.622645) rotate(-41.000000) translate(-9.671970, -8.622645) " x="-1.32802951" y="7.12264521" width="22" height="3"></rect>
        <rect id="Rectangle-Copy-3" transform="translate(21.293893, 12.595492) rotate(36.000000) translate(-21.293893, -12.595492) " x="14.2938926" y="11.0954915" width="14" height="3"></rect>
      </svg>
    );
  }
}
